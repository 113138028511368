body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.4;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 1px;
  /* background-color: rgb(221, 241, 218); */
  /* background-image: url('./asset/_628d2db4-2829-4c14-b270-736a97dfa9c9_ml_resize_x2_colored.jpg');
  background-size:auto ; */
  font: 500;
  background-color: #f5f5f5; /* #faf9f7ef;*/
  width: min(100%, 1800px);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header-background {
  background-color: #cce6cc; /*#d1e7f3;*/
}

.rating-color {
  color: #007300 !important;
}

.MuiTabs-indicator {
  background-color: #007300 !important;
  text-decoration-color: #007300 !important;
}

.skill-info-color {
  background: #007300;
}
